<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <CCardHeader tag="div">
            <CIcon name="cil-grid" /> Thêm Đề xuất Xúc tiến đầu tư của Bộ, Ngành
          </CCardHeader>
          <CCardBody>
            <CForm>
              <div class="w-100">
                <div class="pdf-wrapper">
                  <div class="modal-body modal-container">
                    <div class="pdf-page">
                      <div class="fs-16">
                        <div id="pdf1" class="pdf-content ml-3 mr-3">
                          <div class="text-center fs-16">
                            <strong class="bold">Mẫu C.II.1</strong><br>
                            <strong>Văn bản dự kiến chương trình xúc tiến đầu tư hàng năm</strong><br>
                            <p class="font-italic">(Áp dụng đối với chương trình xúc tiến đầu tư của Bộ/Ủy ban nhân dân cấp
                              tỉnh theo quy định tại Điểm a, Khoản 2, Điều 93 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>
                            <hr>
                          </div>
                          <div class="row fs-16">
                            <div class="col-md-6">
                              <ValidationProvider name="Tên Bộ/UBND Tỉnh" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input id="tenBo_UBNDTinh" type="text" class="form-control"
                                           placeholder="Tên Bộ/UBND Tỉnh"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="item.tenBo_UBNDTinh"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CInput placeholder="Tên Bộ/UBND Tỉnh" horizontal :value.sync="item.tenCoQuanChuTri"/>-->
                              <ValidationProvider name="Số văn bản" rules="required" v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input id="soVanBan" type="text" class="form-control"
                                           placeholder="Số:"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="item.soVanBan"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CInput placeholder="Số:" horizontal :value.sync="item.soVanBan"/>-->
                              <p class="font-weight">V/v dự kiến chương trình đầu tư năm
                                {{ item.nam_ChuongTrinh = Number(getYear(item.ngayVanBan)) + 1 }}</p>
                            </div>
                            <div class="col-md-6">
                              <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</p>
                              <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                              <p class="font-weight font-italic" style="float: right; display: flex">
                                <ValidationProvider name="Địa điểm" rules="required" v-slot="{ errors, valid , touched }">
                              <div role="group" class="form-group form-row">
                                <div class="col-sm-9">
                                  <input id="diaDiem" type="text" class="form-control"
                                         placeholder="Địa điểm"
                                         :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                         v-model="item.diaDiem"/>
                                  <div class="invalid-feedback" v-if="!valid">
                                    <ul>
                                      <li v-for="e in errors" :key="e">{{ e }}</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              </ValidationProvider>
                              <!--                          <CInput placeholder="Địa điểm:" horizontal :value.sync="item.diaDiem"/>-->
                              <span class="mt-2">, ngày {{
                                  getDay(item.ngayVanBan)
                                }} tháng {{
                                  getMonth(item.ngayVanBan)
                                }} năm {{ getYear(item.ngayVanBan) }}</span>
                              </p>
                            </div>
                          </div>
                          <div class="text-center">
                            <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
                          </div>
                          <div>
                            <b>I. Dự kiến chương trình xúc tiến đầu tư năm
                              {{ Number(getYear(item.ngayVanBan)) + 1 }}</b>
                            <div class="col-12">
                              <p>1. Đánh giá sơ bộ hoạt động xúc tiến đầu tư của năm trước:</p>
                              <ValidationProvider name="Đánh giá hoạt động năm trước" rules="required" v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea id="danhGiaHoatDongNamTruoc" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập đánh giá"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="item.danhGiaHoatDongNamTruoc"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CTextarea :rows="1" placeholder="Nhập đánh giá" horizontal :value.sync="item.danhGiaHoatDongNamTruoc"/>-->
                              <p>2. Quan điểm, định hướng, mục tiêu của chương trình xúc tiến đầu tư:</p>
                              <ValidationProvider name="Quan điểm, định hướng, mục tiêu" rules="required" v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea id="quanDiemDinhHuongMucTieu" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập định hướng"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="item.quanDiemDinhHuongMucTieu"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CTextarea :rows="1" placeholder="Nhập quan điểm, định hướng, mục tiêu" horizontal-->
                              <!--                                   :value.sync="item.diaDiem"/>-->
                              <p>3. Dự kiến chương trình xúc tiến đầu tư (trong đó nêu rõ lý do, sự phù hợp...):</p>
                              <ValidationProvider name="Dự kiến CT XTĐT" rules="required" v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea id="duKienChuongTrinh" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập dự kiến CT XTĐT"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="item.duKienChuongTrinh"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CTextarea :rows="1" placeholder="Nhập chương trình dự kiến" horizontal-->
                              <!--                                   :value.sync="item.diaDiem"/>-->
                            </div>
                          </div>
                          <div>
                            <b>II. Tổ chức thực hiện</b>
                            <div class="col-12">
                              <ValidationProvider name="Tổ chức thực hiện" rules="required" v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea id="toChucThucHien" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập (các) tổ chức"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="item.toChucThucHien"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div>
                            <b>III. Kiến nghị, đề xuất</b>
                            <div class="col-12 row">
                              <div class="col">
                                <p class="font-italic">Phụ lục gửi kèm</p>
                                <p>(i) Biểu tổng hợp dự kiến chương trình xúc tiến đầu tư năm  {{ Number(getYear(item.ngayVanBan)) + 1 }} <span class="font-italic">(Mẫu C.II.2)</span>;</p>
                                <p>(ii) Danh mục dự án đầu tư đang có nhà đầu tư quan tâm <span class="font-italic">(Mẫu A.IV.4)</span>.</p>
                              </div>
                              <div class="col mt-5">
                                <CButton class="mt-3" color="primary" @click="toggleCreateOrUpdatePhuLucModal({show:true})">
                                  <i name="cil-highlighter"/> Thêm
                                </CButton>
                              </div>
                            </div>
                            <div class="col-12 row">
                              <div class="col">
                                <div class="float-left">
                                  <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                                  <span class="font-weight">- Như trên;</span> <br>
                                  <span class="font-weight">- Lưu: VT, ...</span> <br>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 row">
                              <div class="col"></div>
                              <div class="col text-center">
                                <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan</span> <br>
                                <span class="font-weight font-italic">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span>
                                <br>
                                <br>
                                <br>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row mb-0 mt-3">
                            <div class="col-12">
                              <b class="mt-5">Đính kèm file</b>
                              <FileUpload ref="upload" @onChange="changeFiles" :type="type"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--              <CCardFooter tag="div" class="text-center">-->
                    <!--                <CButton color="primary" class="mr-2" @click="saveItem">Lưu lại</CButton>-->
                    <!--                <CButton color="danger" class="mr-2" @click="saveItem2">Nộp văn bản</CButton>-->
                    <!--                <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>-->
                    <!--              </CCardFooter>-->
                  </div>
                </div>
              </div>
            </CForm>
            <CElementCover v-if="isLoading"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="primary" class="mr-2" @click="handleSubmit(saveItem)" :disabled="invalid || isSaving">Lưu lại</CButton>
            <CButton color="danger" class="mr-2" @click="handleSubmit(saveItem2)" :disabled="invalid || isSaving">Nộp văn bản</CButton>
            <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
            <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
<!--            <CButton color="primary" @click="handleSubmit(saveItem)" :disabled="invalid || isSaving">Lưu lại</CButton>-->
          </CCardFooter>
          <CTXTDTCuaBoUBNDTinhPhuLucCreateOrUpdateModal :item="item"/>
        </ValidationObserver>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import FileUpload from '@/components/file-upload/FileUpload'
import CTXTDTCuaBoUBNDTinhPhuLucCreateOrUpdateModal from '@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTPhuLucCreateModal'
import { v4 as uuidv4 } from 'uuid'
import { CREATE_DEXUATCTXTDT, TOGGLE_CREATE_UPDATE_PHULUC_MODAL } from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'

export default {
  name: 'DeXuatCTXTDTCreate',
  components: {
    FileUpload,
    CTXTDTCuaBoUBNDTinhPhuLucCreateOrUpdateModal
  },
  data () {
    return {
      invalid: true,
      Attachments: [],
      type: 'DeXuatCTXTDT_BoNganh',
      isLoading: false,
      item: {
        guid: '',
        tenBo_UBNDTinh: '',
        soVanBan: '',
        nam_ChuongTrinh: '',
        diaDiem: '',
        ngayVanBan: new Date(),
        danhGiaHoatDongNamTruoc: '',
        quanDiemDinhHuongMucTieu: '',
        duKienChuongTrinh: '',
        toChucThucHien: '',
        dinhKem: '',
        trangThai: 0
      }
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDT', {
      // formTitle: 'formTitle',
      isSaving: 'isSaving',
      responseResult: 'responseResult'
      // isModalOpen: 'isCreateOrUpdateModalOpen'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDT', {
      createItem: CREATE_DEXUATCTXTDT,
      toggleCreateOrUpdatePhuLucModal: TOGGLE_CREATE_UPDATE_PHULUC_MODAL
    }),
    print () {
      localStorage.setItem('de_xuat_ct_xtdt', JSON.stringify(this.item))
      const routeData = this.$router.resolve({ path: '/de-xuat-ct-xtdt/bieu-in' })
      window.open(routeData.href, '_blank')
    },
    getUserInfo () {
      const dataUser = localStorage.getItem('authData')
      // console.log(dataUser)
      this.item.tenBo_UBNDTinh = JSON.parse(dataUser).fullName
    },
    async saveItem () {
      const validate = await this.$refs.form.validate()
      console.log(validate)
      if (validate) {
        this.item.TrangThai = 1
        this.item.dinhKem = JSON.stringify(this.Attachments)
        // if (this.item.id) {
        //   await this.updateItem(this.item)
        // } else {
        await this.createItem(this.item)
        // }
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          // this.toggleModal({ show: false })
          // this.$emit('refresh-list', true)
          await this.$router.push({ path: '/de-xuat-ct-xtdt' })
        }
      } else {
        this.$toast.error('Thông tin đang thiếu hoặc sai định dạng. Vui lòng kiểm tra lại')
      }
    },
    async saveItem2 () {
      const validate = await this.$refs.form.validate()
      if (validate) {
        this.item.TrangThai = 2
        this.item.dinhKem = JSON.stringify(this.Attachments)
        // if (this.item.id) {
        //   await this.updateItem(this.item)
        // } else {
        await this.createItem(this.item)
        // }
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          // this.toggleModal({ show: false })
          // this.$emit('refresh-list', true)
          await this.$router.push({ path: '/de-xuat-ct-xtdt' })
        }
      } else {
        this.$toast.error('Thông tin đang thiếu hoặc sai định dạng. Vui lòng kiểm tra lại')
      }
    },
    cancel () {
      this.$router.push({ path: '/de-xuat-ct-xtdt' })
    },
    changeFiles (data) {
      this.Attachments = data.ListFileAttachAdd
    },
    getDay: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('YYYY') : ''
  },
  mounted () {
    this.item.guid = uuidv4()
    this.getUserInfo()
    // console.log(this.item.guid)
  }
}
</script>
